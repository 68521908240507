import React, { Component } from 'react';
import { Firebase } from './../services/firebase';
import logo from './../assets/logo.svg';
import { InputBase ,AppBar, Icon, Toolbar, IconButton} from '@material-ui/core';
import './pfAppBar.css';
import ROUTES from '../routes';
import { Link } from 'react-router-dom';

class PfBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: this.props.user || null
    }
  }

  componentDidMount() {
    Firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.setState({user: authUser});
      }
    });
  }

  signout() {
    Firebase.doSignOut();
  }


  render() {
    const { search, searchFunc, back, brand } = this.props;
    return (
      <AppBar position="static" className="homeBar">
          <Toolbar className="home-bar-contents">
            <img className="home-bar-logo" src={logo} alt="picfav" />
            {brand ? <span className="home-bar-brand">PicFav</span> : null}
            {back ? <Link id="home-backButton" to={ROUTES.HOME}><IconButton><Icon>arrow_back</Icon></IconButton></Link> : null}

            
            {search ? <div className="searchBox">
              <div className="searchIcon">
                <Icon>search</Icon>
              </div>
              <InputBase
                placeholder="Search…"
                className="searchInput"
                onInput={searchFunc}
              />
            </div> : null}


            {this.state.user ? <div className="person">
              <img className="person-image" onClick={this.signout} src={this.state.user.photoURL} alt="user" />
            </div> : null}
          </Toolbar>
        </AppBar>
    )
  }
}

export { PfBar };