import React, { Component } from 'react';
import { HomePage, LoginPage, RatePage, AdminPage, DoneContestPage } from './components/pt-lib';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import ROUTES from './routes';
import { Firebase } from './services/firebase';
import { AuthUserContext } from './services/session';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: null
    }
  }

  componentDidMount() {
    Firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.setState({user: authUser});
      }
    });
  }

  render() {
    return (
      <div className="App">
      <AuthUserContext.Provider value={this.state.user}>
        <Router>
          <Route exact path={ROUTES.LOGIN} component={LoginPage} />
          <Route exact path={ROUTES.DEFAULT} component={HomePage} />
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route exact path={ROUTES.CONTEST} component={RatePage} />
          <Route path={ROUTES.ADMIN} component={AdminPage} />
          <Route exact path={ROUTES.DONE} component={DoneContestPage} />
        </Router>
      </AuthUserContext.Provider>
      </div>
    );
  }
}

export default App;
