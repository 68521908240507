import React, { Component } from 'react';
import { Firebase } from './../services/firebase';
import ROUTES from './../routes';
import { PixBox } from './pixbox';
import { Icon, IconButton } from '@material-ui/core';
import { PfBar } from './appBar';

// Styles
import './contest.css';

class RatePage extends Component {

  constructor(props){
    super(props);

    this.contestDetails = this.props.location.state.battle;

    // Set up State
    this.state = {
      user: null,
      portraitSeries: this.contestDetails.portraitSeries ? this.contestDetails.portraitSeries : false,
      roundsLeft: this.contestDetails.playerOnePhotos.length - 1,
      imageSeriesOne: this.contestDetails.playerOnePhotos,
      imageSeriesTwo: this.contestDetails.playerTwoPhotos,
      imageOneScore: 0, 
      imageTwoScore: 0,
      runningScore: [0, 0],
      completed: false,
      round: null,
      rounds: []
    };

    for(let i = 0; i < this.state.roundsLeft + 1; i++) {
      this.state.rounds.push(this.newRound(i));
    }

    this.newRound = this.newRound.bind(this);
    this.onRoundComplete = this.onRoundComplete.bind(this);
  }

  componentDidMount () {
    Firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.setState({user: authUser, round: 0});
        document.getElementById(`contestBox-0`).classList.remove('hidden');
      } else {
        this.props.history.push(ROUTES.LOGIN);
      }
    });
  }

  // Generate a Round

  /*
    Change images to have {imgUrl: url, portrait: bool, score: int}
  */
  newRound(keyID) {
    const indx1 = Math.floor(Math.random() * this.contestDetails.playerOnePhotos.length);
    const indx2 = Math.floor(Math.random() * this.contestDetails.playerTwoPhotos.length);

    const picChooseList = [this.contestDetails.playerOnePhotos[indx1], this.contestDetails.playerTwoPhotos[indx2]];

    const imgOne = picChooseList[Math.floor(Math.random() * 2)];
    const imgTwo = picChooseList.indexOf(imgOne) === 0 ? picChooseList[1] : picChooseList[0];

    const freshRound = <div className="contest-body hidden" key={`contestBox-${keyID}`} id={`contestBox-${keyID}`}>
      <div className="contest-entry contest-entry-one">
        <PixBox portrait={imgOne.portrait} img={imgOne.imgURL} parentHook={this.photoRatingCallback.bind(this)} designatedAs="one" />
     </div>
      <div className="contest-entry-divider"></div>
      <div className="contest-entry contest-entry-two">
        <PixBox portrait={imgTwo.portrait} img={imgTwo.imgURL} parentHook={this.photoRatingCallback.bind(this)} designatedAs="two" />
      </div>
    </div>;

    this.contestDetails.playerOnePhotos.splice(indx1, 1);
    this.contestDetails.playerTwoPhotos.splice(indx2, 1);

    return freshRound;
  }

  // Function to pass down to pixboxes
  photoRatingCallback(score, photo) {
    if (photo === 'one'){

      if (this.state.imageTwoScore !== 0) {
        this.setState({imageOneScore: score, completed: true});
      } else {
        this.setState({imageOneScore: score, completed: false});
      }
      
    } else if (photo === 'two') {
      
      if (this.state.imageOneScore !== 0) {
        this.setState({imageTwoScore: score, completed: true});
      } else {
        this.setState({imageTwoScore: score, completed: false});
      }

    }
  }

  // Handle what happens when a round is complete
  onRoundComplete() {

    if (this.state.roundsLeft === 0) {
      const runScore = [
        this.state.runningScore[0] + this.state.imageOneScore,
        this.state.runningScore[1] + this.state.imageTwoScore
      ];

      Firebase.submitContest(runScore[0], runScore[1], this.contestDetails.id)
      .catch(err => console.log(err));

      this.props.history.push({
        pathname: `/contest/${this.contestDetails.id}/done`,
        state: {scores: runScore, contest: this.contestDetails, user: this.user}
      });
    } else {
      const currentRound = this.state.round;
      document.getElementById(`contestBox-${currentRound}`).classList.add('hidden');
      document.getElementById(`contestBox-${currentRound + 1}`).classList.remove('hidden');


      this.setState({
        runningScore: [
          this.state.runningScore[0] + this.state.imageOneScore,
          this.state.runningScore[1] + this.state.imageTwoScore
        ],
        imageOneScore: 0,
        imageTwoScore: 0,
        roundsLeft: this.state.roundsLeft - 1,
        round: this.state.round + 1,
        completed: false
      })
    }
  }

  render() {
    return (
      <div>
        <PfBar back />
        <div className="contest-header">
          <div className="remaining-rounds">
            Rounds Left: <span className="round-count">{this.state.roundsLeft}</span>
          </div>
          <div className="series-title">
            {this.contestDetails.title}
          </div>
          <div className="series-subtitle">
            {this.contestDetails.desc}
          </div>
        </div>
        {this.state.completed ? <div className="actionButton-container">
          <IconButton className="next-button-wrap" onClick={this.onRoundComplete} ><Icon className="next-button">done</Icon></IconButton>
        </div> : null}
        <div>
          {this.state.rounds}
        </div>
      </div>
    );
  }
}

export { RatePage };