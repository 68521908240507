import React, { Component } from 'react';
import { Firebase } from './../services/firebase';
import { PfBar } from './appBar';
import campImg from './../assets/default-camp.svg';
import springImg from './../assets/default-spring.svg';
import camImg from './../assets/default-cam.svg';
import canonImg from './../assets/default-canon.svg';
import ROUTES from './../routes';
import { CircularProgress, List, Card, CardContent, CardMedia, CardActionArea, Typography } from '@material-ui/core';

import './home.css';

const defaultImages = [campImg, springImg, camImg, canonImg];
class HomePage extends Component {

  constructor(props){
    super(props);

    // Set up State
    this.state = {
      contestListData: [],
      loadingList: true,
      user: null,
      error: null
    };
  }

  componentDidMount() { 

    Firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.setState({user: authUser});
        Firebase.getContestList()
        .then(res => {
          this.setState( {contestListData: res.docs.map(pck => {
            this.setState({loadingList: false}); // Remove loader
            const dat = pck.data();
            return dat;
          }) } );
        })
        .catch(err => this.setState({error: err}));
      } else {
        this.props.history.push(ROUTES.LOGIN);
      }
    });
  }

  navigateToContest(item) {
    this.props.history.push({
      pathname: `/contest/${item.id}`,
      state: {battle: item}
    });
  }

  createContestListItem(item) {
    const defaultCardImg = defaultImages[Math.floor(Math.random() * defaultImages.length)];

    return <Card className="contest-card" key={item.title}>
    <CardActionArea onClick={() => this.navigateToContest(item)}>
      <CardMedia
        component="img"
        alt={item.title}
        className="contest-card-img"
        image={item.coverImg || defaultCardImg}
        title={item.title}
      />
      <CardContent>
        <Typography className="contest-card-title" gutterBottom variant="h5" component="h2">
          {item.title}
        </Typography>
        <Typography className="contest-card-desc" component="p">
          {item.desc}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
  }

  render() {
    const listOfContests = this.state.contestListData.reverse().map(x => this.createContestListItem(x));
    return (
      <div>
        <PfBar brand user={this.state.user} />
        <div className="home-header">
          <div className="home-header-subtitle">Pick a series to compare photos</div>
        </div>
        <div className="contest-list-container">
          {this.state.loadingList ? <CircularProgress className="contest-list-loader" /> : null }
          <List className="contest-list">
            {listOfContests}
          </List>
        </div>
      </div>
    );
  }
}

export { HomePage };