import React, { Component } from 'react';
import GoogleButton from 'react-google-button';
import { Firebase } from './../services/firebase';
import ROUTES from './../routes';
import logo from './../assets/logo.svg';

// Styles 
import './login.css';

class LoginPage extends Component {

  constructor(props){
    super(props);

    this.state = {
      error: null,
      user: null
    }

    this.onSignClick = this.onSignClick.bind(this);
  }

  componentDidMount() {
    Firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.setState({user: authUser});
        this.props.history.push(ROUTES.HOME);
      }
    });
  }

  onSignClick() {
    Firebase.doGoogleLogin()
      .then(socialAuth => {
        return Firebase.db.collection('users').doc(socialAuth.user.uid).set({
          username: socialAuth.user.displayName,
          email: socialAuth.user.email,
          roles: '',
          contests: []
          }, {merge: true});
        })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  render() {
  
    return (
      <div className="login-container">
        {this.state.error ? <div className="errorBox">{JSON.stringify(this.state.error)}</div> : null}
        <div className="brand-card">
          <img className="login-logo" src={logo} alt="PicFav Logo" />
        </div>
        <div className="login-card">
          <GoogleButton onClick={this.onSignClick}/>
        </div>
      </div>
    );
  }
}

export { LoginPage };