import React, { Component } from 'react';
import ROUTES from './../routes';
import winner from './../assets/winner.svg';
import { PfBar } from './appBar';
import './done-contest.css';
import { Icon, IconButton } from '@material-ui/core';

class DoneContestPage extends Component {

  constructor(props){
    super(props);

    this.details = this.props.location.state;

    // Set up State
    this.state = {
      personA: this.details.scores[0],
      personB: this.details.scores[1],
      contest: this.details.contest
    };
  }

  componentDidMount() {
    if (!(this.details.scores && this.details.contest)) {
      this.props.history.push(ROUTES.DEFAULT);
    }
  }

  goHome() {
    this.props.history.push('/');
  }

  render() {
  
    return (
      <div>
        <PfBar back />
        <div className="results-card">
          <div className="results-card-header">
            {this.state.contest.title} Results
          </div>
          <div className="results-card-body">
            <div className="results-card-subheading">
              So turns out you gave {this.state.contest.playerOne} a total of <span className="results-score">{this.state.personA} points</span> , and {this.state.contest.playerTwo} a total of <span className="results-score">{this.state.personB} points.</span>
            </div>
            {this.state.personA !== this.state.personB ?  
              <div className="victory-card">
                <img src={winner} alt="winner" className="winner-image" />
                <span className="winner">{this.state.personA > this.state.personB ? this.state.contest.playerOne : this.state.contest.playerTwo}</span>
              </div> 
            : null}
          </div>

          <IconButton onClick={() => { this.goHome() }}><Icon className="icon-action">refresh</Icon></IconButton>
        </div>
      </div>
    );
  }
}

export { DoneContestPage };