import React, { Component } from 'react';
import star from './../assets/star-filled.svg';
import hollowStar from './../assets/star-empty.svg';
import './pixbox.css';


const defaultImg = '';

class PixBox extends Component {

  constructor(props){
    super();

    // Set up State
    this.state = {
      img: props.img ? props.img : defaultImg,
      score: 0,
      rated: false,
      lightbox: false,
      
      // Handle Star Ratings
      one: hollowStar,
      two: hollowStar,
      three: hollowStar,
      four: hollowStar,
      five: hollowStar
    };

    this.ratePhoto = this.ratePhoto.bind(this);
    this.handleRate = this.handleRate.bind(this);
  }

  handleRate(num) {
    const starList = ['one', 'two', 'three', 'four', 'five'];

    for(let i = 0; i < starList.length; i++) {
      const change = starList[i];

      if (i < (num-1)) {
        this.setState({
          [change]: star
        });
      } else if (i === (num - 1)) {
        if (this.state[change] === star) {
          num = num-1;
        }
        this.setState({
          [change]: this.state[change] === hollowStar ? star : hollowStar
        });
      } else {
        this.setState({
          [change] : hollowStar
        })
      } 
    }
    this.setState({score: num, rated: true});

    this.props.parentHook(num, this.props.designatedAs);
  }

  ratePhoto(event) {
    const score = event.target.id.replace('rate-','');

    switch (score) {
      case 'one':
        this.handleRate(1);
        break;
      case 'two':
        this.handleRate(2);
        break;
      case 'three':
        this.handleRate(3);
        break;
      case 'four':
        this.handleRate(4);
        break;
      case 'five':
        this.handleRate(5);
        break;
      default:
        this.handleRate(0);
    };
  }

  render() {
    // Add conditional to each star to be selected when state.score is equal to or greater than their number

    const portrait = this.props.portrait;

    return (
      <div className="pixbox">
        <div>
          <img src={this.state.img} className={portrait ? "contending-photo-portrait" : "contending-photo"} alt="Choice"/>
        </div>
        <div className="star-group">
          <img id="rate-one" className="star" src={this.state.one} alt="star rating one" onClick={this.ratePhoto}/> 
          <img id="rate-two" className="star" src={this.state.two} alt="star rating two" onClick={this.ratePhoto} />
          <img id="rate-three" className="star" src={this.state.three} alt="star rating three" onClick={this.ratePhoto} />
          <img id="rate-four" className="star" src={this.state.four} alt="star rating four" onClick={this.ratePhoto} />
          <img id="rate-five" className="star" src={this.state.five} alt="star rating five" onClick={this.ratePhoto} />
        </div>
      </div>
    );
  }
}

export { PixBox };
