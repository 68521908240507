import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// Initialize Firebase
var config = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
};

app.initializeApp(config);

class FirebaseWrapper {
  constructor() {
    this.auth = app.auth();
    this.db = app.firestore();
    this.provider = new app.auth.GoogleAuthProvider();


    this.doGoogleLogin = this.doGoogleLogin.bind(this);
    this.doSignOut = this.doSignOut.bind(this);

    this.currentUser = this.auth.currentUser;
  }

  async getUserObject(uid) {
    const userDB = await this.db.collection('users').doc(uid).get()
    .then(res => res)
    .catch(err => console.log(err));

    return userDB;
  }

  doGoogleLogin() {
    return this.auth.signInWithPopup(this.provider);
  }

  doSignOut() {
    return this.auth.signOut();
  }

  async getContestList() {
    const contestList = await this.db.collection('contests').get()
    .then(res => {
      return res
    })
    .catch(err => console.log(err));

    return contestList;
  }

  async submitContest(dedescore, maiscore, contestID) {

    const doc = await this.db.collection('contests').where('id', '==', contestID).get()
    .then(res => res);

    const docID = doc.docs[0].id;
    const playerOne = doc.docs[0].data().playerOne;
    const playerTwo = doc.docs[0].data().playerTwo;

    let contestDetails = await this.db.collection('contests').doc(docID).get()
    .then(res => res)
    .catch(err => console.log(err));

    contestDetails = contestDetails.data();
    const sumDedeScore = contestDetails.playerOneScore + dedescore;
    const sumMaiScore = contestDetails.playerTwoScore + maiscore;

    this.db.collection('contests').doc(docID).update({
      playerOneScore: sumDedeScore,
      playerTwoScore: sumMaiScore
    });

    this.auth.onAuthStateChanged(async user => {
      if (user) {
        const updatedContests = await this.db.collection('users').doc(user.uid).get().then(res => res.data().contests);

        updatedContests.push({
          contest: contestID,
          playerOne: playerOne,
          playerTwo: playerTwo,
          playerOneScore: dedescore, 
          playerTwoScore: maiscore,
          completed: new Date()
        });

        this.db.collection('users').doc(user.uid).update({
          contests: updatedContests
        });
      }
    })
  }


  async createContest(contest) {

    const pck = {
      title: contest.title,
      desc: contest.desc,
      playerOne: contest.playerOne,
      playerTwo: contest.playerTwo,
      playerOneScore: 0,
      playerTwoScore: 0,
      id: '',
      playerOnePhotos: contest.playerOnePhotos.split(',').map(x => {return {imgURL: x, portrait: false, score: 0 };}),
      playerTwoPhotos: contest.playerTwoPhotos.split(',').map(x => {return {imgURL: x, portrait: false, score: 0 };}),
      portraitSeries: contest.portraitSeries,
      coverImg: contest.coverImg
    }

    const docID = await this.db.collection('contests').add(pck).then(res => res.id);

    this.db.collection('contests').doc(docID).update({id: docID});
  }

}

const Firebase = new FirebaseWrapper();

export { Firebase };