import React, { Component } from 'react';
import { Firebase } from './../services/firebase';
import ROUTES from './../routes';
import { Paper,Icon, IconButton, TableBody, Table, TableHead, TableRow, TableCell, CircularProgress, Card, CardContent, Typography, ListItem, ListItemText, TextField, Button } from '@material-ui/core';

import './admin.css';

class AdminPage extends Component {

  constructor(props){
    super(props);

    // Set up State
    this.state = {
      contestListData: [],
      loadingList: true,
      addContest: false,
      user: null,
      contest: {
        title: '',
        desc: '',
        playerOne: '',
        playerTwo: '',
        playerOnePhotos: '',
        playerTwoPhotos: '',
        portraitSeries: false,
        coverImg: null
      }
    };

    this.showAddContest = this.showAddContest.bind(this);
    this.submitContest = this.submitContest.bind(this);
  }

  componentDidMount() {
    Firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        Firebase.getUserObject(authUser.uid)
        .then(res => {
          let doc = res.data();
          if (doc.roles === 'admin') {
            this.setState({user: authUser});
            Firebase.getContestList()
            .then(res => {
              this.setState( {contestListData: res.docs.map(pck => {
                this.setState({loadingList: false}); // Remove loader
                const dat = pck.data();
                return dat;
              })})});
          } else {
            this.props.history.push(ROUTES.HOME);
          }
        })
      } else {
        this.props.history.push(ROUTES.LOGIN);
      }
    });
  }

  renderContestItem(x) {
    const leader = x.playerOneScore === x.playerTwoScore ? 'Tie' : (x.playerOneScore > x.playerTwoScore ? 'Dede' : 'Jem');
    return (<ListItem key={x.title} className="contest-result-list-item" ><ListItemText primary={x.title} secondary={leader}></ListItemText></ListItem>)
  }

  showAddContest() {
    if (this.state.addContest) {
      this.setState({addContest: false});
    } else {
      this.setState({addContest: true});
    }
  }

  onInputChange(event) {
    
  }

  submitContest() {
    const data = {
      title: document.getElementById('contest-name').value,
      desc: document.getElementById('contest-desc').value,
      playerOne: document.getElementById('contest-playerOne').value,
      playerTwo: document.getElementById('contest-playerTwo').value,
      playerOnePhotos: document.getElementById('contest-playerOne-photos').value,
      playerTwoPhotos: document.getElementById('contest-playerTwo-photos').value,
      portraitSeries: false,
      coverImg: null
    }
    console.log(data);
    Firebase.createContest(data);
  }

  render() {
    function getSum(total, num) {
      return total + num;
    }

    // Get the most common element in an array
    function modeString(array) {
      if (array.length === 0)
          return null;

      var modeMap = {},
          maxEl = array[0],
          maxCount = 1;

      for(var i = 0; i < array.length; i++)
      {
          var el = array[i];

          if (modeMap[el] === null)
              modeMap[el] = 1;
          else
              modeMap[el]++;

          if (modeMap[el] > maxCount)
          {
              maxEl = el;
              maxCount = modeMap[el];
          }
          else if (modeMap[el] === maxCount)
          {
              maxEl += '&' + el;
              maxCount = modeMap[el];
          }
      }
      return maxEl;
    }

    // Get a unique count of players who've participated
    function getUniquePlayersAndLeader(contestList) {
      const players = [];
      const winners = [];

      contestList.forEach(x => {
        if (!players.includes(x.playerOne)) {
          players.push(x.playerOne);
        }
        if (!players.includes(x.playerTwo)) {
          players.push(x.playerTwo);
        }

        if (x.playerOneScore > x.playerTwoScore) {
          winners.push(x.playerOne);
        } else if (x.playerTwoScore > x.playerOneScore) {
          winners.push(x.playerTwo);
        } else {
          winners.push('Tie');
        }
      });

      return [players.length, modeString(winners)];
    }

    const playerOneTotal = this.state.contestListData.map(x => x.playerOneScore).reduce(getSum, 0);
    const playerTwoTotal = this.state.contestListData.map(x => x.playerTwoScore).reduce(getSum, 0);
    const contestCount = this.state.contestListData.length; // Total number of contests
    const statPack  = getUniquePlayersAndLeader(this.state.contestListData); // Number of players and Leading player
    let leadingPlayer = statPack[1]; 
    let numPlayers = statPack[0];

    
    
    return (
      <div>
        {!this.state.loadingList ? 
          <Card className="scoreCard">
          <CardContent>
            <Typography className="scoreCard-title">
              PicFav Control
              <span className="title-date">{ new Date().toDateString() }</span>
            </Typography>
            <div className="scoreCard-scores">
              <div className="score">
                <IconButton className="add-contest-button" onClick={this.showAddContest}>
                  <Icon className="add-contest-icon-button">{this.state.addContest ? 'close' : 'add'}</Icon>
                </IconButton>
                <div className="score-owner">Add Contest</div>
              </div>
              <div className="scoreDivide"></div>
              <div className="score">
                {contestCount} 
                <div className="score-owner">Contests</div>
              </div>
              <div className="scoreDivide"></div>
              <div className="score">
                {leadingPlayer}
                <div className="score-owner">Leads</div>
              </div>
              <div className="scoreDivide"></div>
              <div className="score">
                {numPlayers}
                <div className="score-owner">Players</div>
              </div>
            </div>

            <div className="addContest">
              {this.state.addContest ? 
              <div className="add-contest-form">
                Add New Contest
                <form className="newContestForm" autoComplete="off">
                  <TextField
                    id="contest-name"
                    label="Title"
                    margin="normal"
                    onChange={this.onInputChange}
                    variant="outlined"
                  />
                  <TextField
                    id="contest-desc"
                    multiline
                    rowsMax="4"
                    label="Description"
                    onChange={this.onInputChange}
                    margin="normal"
                    variant="outlined"
                  />
                  <div className="half-box">
                    <TextField
                      id="contest-playerOne"
                      className="contest-input-half"
                      label="Photographer One"
                      onChange={this.onInputChange}
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      id="contest-playerTwo"
                      className="contest-input-half"
                      label="Photographer Two"
                      onChange={this.onInputChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </div>
                  <TextField
                    id="contest-playerOne-photos"
                    className="contest-input-full"
                    label="Player One Photos"
                    margin="normal"
                    variant="outlined"
                    helperText="Enter URLs deliniated by commas"
                  />
                  <TextField
                    id="contest-playerTwo-photos"
                    className="contest-input-full"
                    label="Player Two Photos"
                    margin="normal"
                    variant="outlined"
                    helperText="Enter URLs deliniated by commas"
                  />
                </form>
                <div className="contest-action-buttons">
                  <Button onClick={this.submitContest} >Submit</Button>
                  <Button onClick={this.showAddContest}>Cancel</Button>
                </div>
              </div> : 
              null}
            </div>
          </CardContent>
        </Card>
          : null}
      <div className="contests">
        {this.state.loadingList ? <CircularProgress className="contest-list-loader" /> : null }

        {!this.state.loadingList ? 
          <Paper className="table-background">
          <Table className="contest-results-table">
            <TableHead>
              <TableRow>
                <TableCell>Contest</TableCell>
                <TableCell align="right">Winner</TableCell>
                <TableCell align="right">Player 1</TableCell>
                <TableCell align="right">Player 2</TableCell>
                <TableCell align="right">Player 1 Score</TableCell>
                <TableCell align="right">Player 2 Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.contestListData.map(row => (
                <TableRow key={row.title}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell align="right">{row.playerOneScore === row.playerTwoScore ? 'Tie' : (row.playerOneScore > row.playerTwoScore ? row.playerOne : row.playerTwo)}</TableCell>
                  <TableCell align="right">{row.playerOne}</TableCell>
                  <TableCell align="right">{row.playerTwo}</TableCell>
                  <TableCell align="right">{row.playerOneScore}</TableCell>
                  <TableCell align="right">{row.playerTwoScore}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell rowSpan={3} colSpan={3} />
                <TableCell colSpan={2}>Total Points Collected</TableCell>
                <TableCell align="right" className="table-total">{playerOneTotal + playerTwoTotal}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper> 
        : null}
      </div>
      </div>
    );
  }
}

export { AdminPage };